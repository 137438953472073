<template>
  <div>
    <div class="i-layout-page-header header-title">
      <div class="fl_header">
        <router-link v-if="$route.params.id != 49" :to="{ path: $config.routePre + '/system/config/system_group' }">
          <Button icon="ios-arrow-back" size="small" type="text">返回</Button>
        </router-link>
        <Divider v-if="$route.params.id != 49" type="vertical" />
        <span class="ivu-page-header-title mr20" style="padding: 0" v-text="$route.meta.title"></span>
      </div>
    </div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <Row class="ivu-mt box-wrapper">
        <Col :xs="24" :sm="24" :md="6" :lg="4" class="left-wrapper" v-if="!$route.params.id && groupAll.length">
          <Menu theme="light" :active-name="sortName" width="auto">
            <MenuGroup>
              <MenuItem
                :name="item.id"
                class="menu-item"
                v-for="(item, index) in groupAll"
                :key="index"
                @click.native="handleBindMenuItem(item, index)">
                {{ item.name }}
              </MenuItem>
            </MenuGroup>
          </Menu>
        </Col>
        <Col :xs="24" :sm="24" :md="$route.params.id ? 24 : 17" :lg="$route.params.id ? 24 : 20" ref="rightBox">
          <Form
            ref="formValidate"
            :model="searchWhere"
            :label-width="80"
            label-position="right"
            @submit.native.prevent>
            <Row type="flex" :gutter="24">
              <Col v-bind="grid">
                <FormItem label="是否显示：">
                  <Select v-model="searchWhere.status" placeholder="请选择" clearable @on-change="handleSearchs">
                    <Option value="1">显示</Option>
                    <Option value="0">不显示</Option>
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row type="flex">
              <Col v-bind="grid">
                <Button type="primary" icon="md-add" @click="handleGroupAdd('添加数据')" class="mr20">添加数据</Button>
              </Col>
            </Row>
          </Form>
          <Table
            :columns="columns"
            :data="dataList"
            ref="table"
            class="l-m-t25"
            :loading="loading"
            highlight-row
            no-userFrom-text="暂无数据"
            no-filtered-userFrom-text="暂无筛选结果">
            <template slot-scope="{ row }" slot="status">
              <i-switch
                v-model="row.status"
                :value="row.status"
                :true-value="1"
                :false-value="0"
                @on-change="handleChangeIsShow(row)"
                size="large">
                <span slot="open">显示</span>
                <span slot="close">隐藏</span>
              </i-switch>
            </template>
            <template slot-scope="{ row, index }" slot="action">
              <a @click="handleEdit(row, '编辑')">编辑</a>
              <Divider type="vertical" />
              <a class="l-color-error" @click="handleDelete(row, '删除这条信息', index)">删除</a>
            </template>
          </Table>
          <div class="acea-row row-right l-m-t25">
            <Page :total="total" :current="searchWhere.page" show-elevator show-total @on-change="handlePageChange" :page-size="searchWhere.limit"
            />
          </div>
        </Col>
      </Row>
    </Card>
  </div>
</template>

<script>
import mixins from '@/mixins'
import {
  getGroupAllApi,
  getGroupDataListApi,
  getGroupDataHeaderApi,
  createGroupDataApi,
  setGroupDataStatusApi,
  getGroupDataEditApi
} from '@/api/system'

export default {
  name: 'list',
  mixins: [mixins],
  data () {
    return {
      // 搜索数据
      searchWhere: {
        page: 1,
        limit: 15,
        gid: 0,
        status: ''
      },

      // 侧边数据
      sortName: null,
      groupAll: [],

      // 表格数据
      dataList: [],
      columns: []
    }
  },
  watch: {
    $route (to, from) {
      if (this.$route.params.id) {
        this.getListRequest()
        this.getListHeaderRequest()
      } else {
        this.getGroupAllRequest()
      }
    }
  },
  mounted () {
    if (this.$route.params.id) {
      this.getListRequest()
      this.getListHeaderRequest()
    } else {
      this.getGroupAllRequest()
    }
  },
  methods: {
    // 获取数据列表
    getListRequest () {
      this.loading = true
      this.searchWhere.gid = this.$route.params.id ? this.$route.params.id : this.searchWhere.gid
      this.searchWhere.status = this.searchWhere.status || ''
      getGroupDataListApi(this.searchWhere, this.getUrl('')).then(async (res) => {
        let data = res.data
        this.dataList = data.list
        this.total = data.count
        this.loading = false
      }).catch((res) => {
        this.loading = false
        this.$Message.error(res.msg)
      })
    },
    getUrl (type) {
      let url = 'setting/group_data' + type
      if (this.$route.params.id) {
        let arr = {
          setting_groupDataSign: 'setting/sign_data' + type,
          setting_groupDataOrder: 'setting/order_data' + type,
          setting_groupDataUser: 'setting/usermenu_data' + type,
          setting_groupDataPoster: 'setting/poster_data' + type,
          marketing_storeSeckillData: 'setting/seckill_data' + type
        }
        if (arr[this.$route.name] === undefined) return url
        return arr[this.$route.name]
      } else {
        return url
      }
    },
    // 获取表格头部
    getListHeaderRequest () {
      this.loading = true
      let data = {
        gid: this.$route.params.id ? this.$route.params.id : this.formValidate.gid
      }
      getGroupDataHeaderApi(data, this.getUrl('/header')).then(async (res) => {
        let data = res.data
        let header = data.header
        let index = []
        header.forEach(function (item, i) {
          if (item.type === 'img') {
            index.push(i)
          }
        })
        index.forEach(function (item) {
          header[item].render = (h, params) => {
            let arr = params.row[header[item].key]
            let newArr = []
            if (arr !== undefined && arr.length) {
              arr.forEach(function (e, i) {
                newArr.push(
                  h('div', {
                    style: {
                      width: '36px',
                      height: '36px',
                      'border-radius': '4px',
                      cursor: 'pointer',
                      display: 'inline-block'
                    }
                  }, [
                    h('img', {
                      attrs: {
                        src: params.row[header[item].key][i]
                      },
                      style: {
                        width: '100%',
                        height: '100%'
                      }
                    })
                  ])
                )
              })
            }
            return h('viewer', newArr)
          }
        })
        this.columns = header
        this.loading = false
      }).catch((res) => {
        this.loading = false
        this.$Message.error(res.msg)
      })
    },
    // 获取全部数据
    getGroupAllRequest () {
      getGroupAllApi().then(async (res) => {
        this.groupAll = res.data
        this.sortName = res.data[0].id
        this.searchWhere.gid = res.data[0].id
        this.getListHeaderRequest()
        this.getListRequest()
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 编辑
    handleEdit (row) {
      let data = {
        gid: row.gid
      }
      this.$modalForm(getGroupDataEditApi(data, this.getUrl(`/${row.id}/edit`))).then(() => this.getListRequest())
    },
    // menus事件
    handleBindMenuItem () {},
    // 状态的修改
    handleChangeIsShow (row) {
      setGroupDataStatusApi(this.getUrl(`/set_status/${row.id}/${row.status}`)).then(async (res) => {
        this.$Message.success(res.msg)
        this.getListRequest()
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 处理删除
    handleDelete (row, tit, num) {
      let delfromData = {
        title: tit,
        num: num,
        url: this.getUrl(`/${row.id}`),
        method: 'DELETE',
        ids: ''
      }
      this.$modalSure(delfromData).then((res) => {
        this.$Message.success(res.msg)
        this.tabList.splice(num, 1)
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 数据添加
    handleGroupAdd () {
      this.$modalForm(
        createGroupDataApi(
          { gid: this.$route.params.id ? this.$route.params.id : this.searchWhere.gid },
          this.getUrl('/create')
        )
      ).then(() => this.getListRequest())
    },
    // 条件搜索
    handleSearchs () {
      this.searchWhere.page = 1
      this.getListRequest()
    },
    // 分页搜索
    handlePageChange (index) {
      this.searchWhere.page = index
      this.getListRequest()
    }
  }
}
</script>

<style scoped>

</style>
